<template>
  <Dialog :name="name" @close="clearPickers">
    <template #title>
      <div>Перенос тайм слота</div>
    </template>

    <el-row :class="{ 'flex-w': isMobile }">
      <el-form
        ref="ShiftSlotForm"
        :model="gettingSlotsForm"
        :rules="rulesFormGettingSlots"
        label-width="300px"
        class="drivers-slots-form"
        label-position="top"
        @submit.native.prevent
      >
        <iq-select-date-form
          v-model.number="gettingSlotsForm['day']"
          size="fullWidth"
          label="Дата прибытия:"
          prop="day"
          type="date"
          placeholder="Укажите день"
          format="dd/MM/yyyy"
          :options="pickerOptions"
        />
        <iq-select-form
          v-model="gettingSlotsForm['time']"
          full-width
          label="Время прибытия:"
          prop="time"
          :message="'Для выбора времени укажите день'"
          :disabled="!gettingSlotsForm['day']"
          placeholder="Укажите время"
          :options="timeSelectOptions"
        />
        <el-form-item style="margin-top: 32px">
          <iq-button
            color="main-dark"
            size="fullWidth"
            @onClick="submitForm('ShiftSlotForm')"
          >
            <i class="el-icon-upload2 button-icon" />
            Запросить тайм слот
          </iq-button>
        </el-form-item>
      </el-form>
    </el-row>
  </Dialog>
</template>

<script>
import { DR_GET_TERMINALS, SET_TIMEZONE_OFFSET } from '@/store/actions'
import { GET_TIME } from '@/constants/date'
import { TIMESLOT_SHIFT_PARAMS } from '@/constants/dialogs'
import {
  getTimeslotTzTime,
  getTzTime,
  isPickerDateToday,
  nowTz,
  pickerOptions,
} from '@/core'
import { mapGetters, mapMutations } from 'vuex'
import { rulesFormGettingSlots } from '@/views/drivers-home/data'
import { timeForSelect } from '@/constants/time'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton'
import IqSelectDateForm from '@/UI/select/IqSelectDateForm'
import IqSelectForm from '@/UI/select/IqSelectForm'

export default {
  name: 'TimeslotShiftParams',
  components: {
    Dialog,
    IqButton,
    IqSelectDateForm,
    IqSelectForm,
  },
  props: {
    timeslot: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rulesFormGettingSlots,
      pickerOptions,
      name: TIMESLOT_SHIFT_PARAMS,
      gettingSlotsForm: {
        time: null,
        day: null,
      },
      dataRequest: null,
    }
  },
  computed: {
    ...mapGetters({
      terminalsList: DR_GET_TERMINALS,
    }),
    timeSelectOptions() {
      let minTime

      isPickerDateToday(this.gettingSlotsForm.day)
        ? (minTime = Number(getTzTime(nowTz(), GET_TIME).slice(0, 2)))
        : (minTime = 0)

      return timeForSelect(24).filter(
        item => Number(item.value.slice(0, 2)) >= minTime,
      )
    },
  },
  watch: {
    timeslot(val) {
      if (val) {
        let currentTerminal = this.terminalsList.find(
          item => item.id === val.unload_id,
        )
        // устанавливаем часовой пояс терминала для правильного отображения дейт- и таймпикеров
        let timezone = currentTerminal.timezone_utc_offset

        localStorage.setItem('terminal-timezone', timezone)
        this.setTimezoneOffset(timezone)

        this.clearPickers()
      }
    },
  },
  methods: {
    ...mapMutations({
      setTimezoneOffset: SET_TIMEZONE_OFFSET,
    }),
    getHoursMinutes(date, type) {
      if (type === 'hours') {
        const reg = /(\b[0-2]?\d):/

        return Number(date.match(reg)[1])
      }
      if (type === 'minutes') {
        const reg = /:([0-5]\d\b)/

        return Number(date.match(reg)[1])
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let getTimeSlot = getTimeslotTzTime(
            this.gettingSlotsForm.day,
            this.getHoursMinutes(this.gettingSlotsForm.time, 'hours'),
            this.getHoursMinutes(this.gettingSlotsForm.time, 'minutes'),
          )

          this.dataRequest = {
            time: getTimeSlot,
          }
          this.$emit('getTimeslot', this.dataRequest)
        }
      })
    },
    clearPickers() {
      this.gettingSlotsForm = { time: null, day: null }
      this.$nextTick(() => this.$refs.ShiftSlotForm.clearValidate())
    },
  },
}
</script>
<style lang="sass">
.button-icon
  margin-right: 5px
</style>
